import { ChatDocument, ChatMessageDocument } from '@models/chat';
import { FirebaseCallable, db, functions, httpsCallable } from '@util/firebase';
import { formatCurrency } from '@util/index';
import { OrderDocument } from '@models/order';
import {
  CollectionReference,
  DocumentData,
  collection,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import { OfferDocument } from 'models/offer';
import { redactPhonesAndEmailsFromStr } from '../messages';
import { Variation, ProductDocument } from 'models/product';
import { COUNTRIES } from 'models/shared';
import { AddressDocument } from 'models/address';

const messagesRef = collection(
  db,
  'messages'
) as CollectionReference<ChatDocument>;
const offersRef = collection(
  db,
  'offers'
) as CollectionReference<OfferDocument>;
const getDocRef = (id: string) =>
  doc<OfferDocument, DocumentData>(offersRef, id);

export const getOfferId = () => {
  const newDocRef = doc(offersRef);
  const id = newDocRef.id;
  return id;
};

export async function createOffer(offer: OfferDocument) {
  if (!offer.is_auction) {
    const message: ChatMessageDocument = {
      uid: offer.buyer_id,
      offer_amount: offer.price,
      content: offer.message!,
      is_img: false,
      created_at: Date.now(),
      skip_notification: true,
      is_auto: false,
    };

    const chat: Omit<ChatDocument, 'id'> = {
      product_id: offer.product_id,
      buyer_id: offer.buyer_id,
      seller_id: offer.seller_id,
      is_offer: true,
      uids: offer.uids,
      flagged: false,
      from_web: true,
      last_time: Date.now(),
      created_at: Date.now(),
      messages: [message],
      unread: {
        [offer.buyer_id]: true,
        [offer.seller_id]: true,
      },
      is_expert: false,
    };

    offer.chat_id = await createChat(chat);

    const chatRef = doc(messagesRef, offer.chat_id);
    await updateDoc(chatRef, { offer_id: offer.id });
  }
  const docRef = getDocRef(offer.id!);
  await setDoc(docRef, offer);
}

export async function updateOffer(
  offerId: string,
  updateData: Partial<OfferDocument>
) {
  const docRef = getDocRef(offerId);
  await updateDoc(docRef, updateData);
}

export async function createOfferIntent(amount: number, customer?: string) {
  const res = await httpsCallable<
    { amount: number; customer?: string },
    { pi_id: string; data: string }
  >(
    functions,
    FirebaseCallable.createOfferIntent
  )({ amount, customer });
  return res.data;
}

export async function updateOfferIntent(args: OfferDocument) {
  const res = await httpsCallable<OfferDocument, OfferDocument>(
    functions,
    FirebaseCallable.updateOfferIntent
  )(args);
  return res.data;
}

export async function acceptOffer(offer_id: string) {
  const res = await httpsCallable<{ offer_id: string }, OrderDocument>(
    functions,
    FirebaseCallable.acceptOffer
  )({ offer_id });
  return res.data;
}

export async function createChat(chat: Omit<ChatDocument, 'id'>) {
  const docRef = doc(messagesRef);
  const chatDoc: ChatDocument = {
    ...chat,
    id: docRef.id,
  };
  await setDoc(docRef, chatDoc);
  return docRef.id;
}

export async function getOffer(offer_id: string): Promise<OfferDocument> {
  const offerDoc = await getDoc(doc(offersRef, offer_id));
  return (offerDoc.data() as OfferDocument) ?? null;
}

export const getOfferMessage = (
  productTitle: string,
  price: number,
  shipping: number,
  tax: number,
  customMsg?: string,
  selectedVariations?: Variation[] | null,
  size?: ProductDocument['size'],
  address?: AddressDocument
): string => {
  const priceStr = formatCurrency(price);
  const shippingStr = formatCurrency(shipping);
  const taxStr = formatCurrency(tax);
  const total = formatCurrency(price + shipping + tax);
  const sizeStr =
    size && (size.letter || size.number)
      ? `(Size ${size.letter || size.number})`
      : selectedVariations?.length
      ? `(Sizes ${selectedVariations.map((v) => v.size).join('/')})`
      : '';
  const country =
    COUNTRIES.find((c) => c.code === address?.country_code)?.name ??
    address?.country_code;
  let message = `New Offer: ${productTitle} ${sizeStr} for ${total} (${priceStr} Offer + ${shippingStr} Shipping + ${taxStr} Tax) to ${country}`;
  if (customMsg)
    message +=
      ' with the following message: ' + redactPhonesAndEmailsFromStr(customMsg);
  return message;
};

export async function getOfferChats(uid: string) {
  const q = query(
    messagesRef,
    where('uids', 'array-contains', uid),
    where('is_offer', '==', true)
  );
  const { docs } = await getDocs(q);
  return docs.map((d) => d.data());
}

export function setOfferState(id: string, state: number) {
  return updateDoc(getDocRef(id), { state });
}

export async function getOfferById(id: string) {
  const doc = await getDoc(getDocRef(id));
  return doc.data();
}

export async function getPendingOfferCount(uid?: string) {
  // NOTE: fixes firestore access denied errors when uid is empty on initial page load
  if (!uid) {
    return 0;
  }
  const q = query(
    offersRef,
    where('uids', 'array-contains', uid),
    where('is_auction', '==', false),
    where('state', '==', 0)
  );
  const res = await getCountFromServer(q);
  return res.data()?.count ?? 0;
}

export async function getActiveOffers(uid: string) {
  const q = query(
    offersRef,
    where('buyer_id', '==', uid),
    where('state', '==', 0)
  );
  const { docs } = await getDocs(q);
  return docs.map((d) => d.data());
}

export const calculateEarningsFromOffer = (
  offer: Pick<
    OfferDocument,
    'total' | 'shipping_cost' | 'rate_id' | 'price' | 'address' | 'tax'
  >,
  sellerTaxStates: string[] = [],
  product: ProductDocument
) => {
  const fee = product.fee_override ?? 0.1;
  const processing = offer.total * 0.03;
  const shipping = offer.shipping_cost ?? 0;
  const flatRateShipping = !offer.rate_id && shipping ? shipping : 0;
  const label_cost = offer.rate_id ? shipping : 0;
  const commission = fee * (flatRateShipping + offer.price);
  const sellerRemits =
    offer.address?.state_province &&
    sellerTaxStates.includes(offer.address.state_province);
  const taxes = sellerRemits ? 0 : offer.tax ?? 0;
  // const ins_amount = offer.insurance_amount ?? 0;
  const net = offer.total - processing - commission - label_cost - taxes;
  return net;
};
