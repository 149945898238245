'use client';

import BaseModal from '@c/modals/BaseModal';
import { useQueryClient } from '@tanstack/react-query';
import Button from '@ui/Button';
import { deleteListing } from '@util/firestore/products';
import { useToastContext } from 'context/ToastContext';
import { ProductDocument } from 'models/product';
import { useState } from 'react';

export default function DeleteListingModal({
  open,
  setOpen,
  product,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  product: ProductDocument;
}) {
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const { showSuccessToast, showErrorToast } = useToastContext();

  const onDeleteListing = async () => {
    try {
      setLoading(true);
      await deleteListing(product?.id ?? '');

      queryClient.invalidateQueries({
        queryKey: ['listings'],
      });

      showSuccessToast('Listing deleted successfully');
    } catch (e) {
      showErrorToast((e as Error).message);
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  return (
    <BaseModal isOpen={open} dismiss={() => setOpen(false)} hideCloseIcon>
      <div className="flex h-[25rem] flex-col justify-between p-8">
        <div className="flex flex-col gap-y-2">
          <span className="flex items-center gap-[1.6rem] text-[2.4rem] font-medium">
            Delete Listing
          </span>
          <span className="max-w-[40rem] pt-6 text-[2rem] text-brand-dark-gray">
            Are you sure you want to delete this listing? This action cannot be
            undone.
          </span>
        </div>

        <div className="flex w-full justify-end gap-x-2">
          <Button
            text="Cancel"
            onClick={() => setOpen(false)}
            type="tertiary"
          />

          <Button
            text={loading ? 'Deleting...' : 'Delete'}
            onClick={onDeleteListing}
            type="secondary"
          />
        </div>
      </div>
    </BaseModal>
  );
}
