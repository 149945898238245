import { ProductDocument } from '@models/product';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getItem, getItems, logEvent } from '@util/analytics';
import { db } from '@util/firebase';
import {
  getChatDocumentById,
  sendMessageToChat,
} from '@util/firestore/messages';
import {
  acceptOffer,
  calculateEarningsFromOffer,
  createOfferIntent,
  getOffer,
  getOfferMessage,
  setOfferState,
  updateOffer,
} from '@util/firestore/offers/';
import { calculateEarnings } from '@util/firestore/orders';
import { getProductById } from '@util/firestore/products';
import { getPublicUserDoc, getUserById } from '@util/firestore/users';
import { getHostUrl } from '@util/index';
import { logError } from '@util/logError';
import { useAuth } from 'context/AuthContext';
import { useStripeContext } from 'context/StripeContext';
import { useToastContext } from 'context/ToastContext';
import dayjs from 'dayjs';
import { collection, doc, onSnapshot } from 'firebase/firestore';
import { ChatMessageDocument } from 'models/chat';
import { OfferDocument } from 'models/offer';
import { useRouter } from 'next/navigation';
import { useCallback, useEffect, useState } from 'react';

export const useOffers = (offer_id?: string) => {
  const [isOpen, setIsOpen] = useState(false);
  const [stripe, setStripe] = useState<any>(null);
  const [isAcceptOpen, setAcceptIsOpen] = useState(false);
  const [isCounterOpen, setCounterIsOpen] = useState(false);
  const { userDoc } = useAuth();
  const queryClient = useQueryClient();
  const router = useRouter();
  const { showErrorToast } = useToastContext();
  const { stripePromise } = useStripeContext();

  const [realtimeOffer, setRealtimeOffer] = useState<OfferDocument | null>(
    null
  );
  useEffect(() => {
    if (!offer_id) return;
    const offerQuery = doc(collection(db, 'offers'), offer_id);
    const unsubscribe = onSnapshot(offerQuery, (snapshot) => {
      const data = snapshot.data();
      if (!data) return;
      setRealtimeOffer(data as OfferDocument);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!stripe) {
      const initializeStripe = async () => {
        const stripeInstance = await stripePromise;
        setStripe(stripeInstance);
      };
      initializeStripe();
    }
    return () => {
      setStripe(null);
    };
  }, [stripePromise]);

  const { data: offer } = useQuery({
    queryKey: ['offer', offer_id],
    queryFn: () => getOffer(offer_id!),
    enabled: !!offer_id,
  });

  const { data: product } = useQuery({
    queryKey: ['product', offer?.product_id],
    queryFn: () => getProductById(offer?.product_id ?? ''),
    enabled: !!offer?.product_id,
  });
  const { data: seller } = useQuery({
    queryKey: ['seller', offer?.seller_id],
    queryFn: () => getUserById(offer!.seller_id),
    enabled: !!offer?.seller_id,
  });

  const declineThatOffer = useCallback(async () => {
    if (!offer_id) return;

    try {
      await setOfferState(offer_id, 2);
      queryClient.invalidateQueries({ queryKey: ['offer', offer_id] });
      if (product) {
        logEvent(
          'declined_offer',
          {
            items: [getItem(product)],
          },
          userDoc?.uid
        );
      }
    } catch (error) {
      logError('Error declining offer', error as string | undefined);
    }
  }, [offer_id, queryClient, product, userDoc?.uid]);

  const acceptThatOffer = async () => {
    if (!offer_id || !userDoc) return;
    if (realtimeOffer?.state !== 0) {
      showErrorToast('This offer has already been completed.');
      return;
    }
    try {
      const order = await acceptOffer(offer_id);

      logEvent('purchase', {
        transaction_id: order.id,
        value: order.total,
        currency: 'USD',
        tax: order.tax ?? 0,
        shipping: order.shipping_total ?? 0,
        items: getItems([
          product ??
            ({
              id: offer_id,
              title: offer_id,
              price: order.total,
            } as ProductDocument),
        ]),
      });

      if (product) {
        const now = Date.now();
        const created = product.created || product.date_added;
        if (created) {
          logEvent('time_to_match', {
            ...getItem(product),
            msec: now - created,
            hours: dayjs(created).diff(now, 'hours'),
            days: dayjs(created).diff(now, 'days'),
            created,
            sold: now,
          });
        }
      }
      router.push(`/dashboard/orders/${order.id}`);
    } catch (e) {
      showErrorToast((e as Error).message);
      logError(e);
    } finally {
      const offerQ = queryClient.invalidateQueries({
        queryKey: ['offer', offer_id],
      });
      const orderQ = queryClient.invalidateQueries({
        queryKey: ['orders', userDoc?.uid],
      });
      await Promise.all([offerQ, orderQ]);
    }
    setAcceptIsOpen(false);
  };

  const counterOffer = async ({
    offer,
    product,
  }: {
    offer: OfferDocument;
    product: ProductDocument;
  }) => {
    if (!userDoc || !stripe || !offer.price) return;
    const currProduct = await getProductById(product?.id);
    if (!currProduct || currProduct.out_of_stock) return;

    const seller = await getPublicUserDoc({
      uid: currProduct.seller_id,
      noCache: true,
    });

    if (!seller) {
      throw new Error('Seller not found');
    }

    const newOffer: Partial<OfferDocument> = {
      price: offer.price,
      total: offer.total,
      is_counter: true,
      created: Date.now(),
    };

    try {
      const offerIntent = await createOfferIntent(
        offer.price,
        offer.customer_id
      );

      const return_url = `${getHostUrl()}/dashboard/offers?retry=true`;

      const { error } = await stripe.confirmCardSetup(offerIntent.data, {
        payment_method: offer.payment.id,
        return_url,
      });
      if (error) {
        showErrorToast(
          `There was an issue with the buyer's payment method: ` +
            error.message +
            '. Contact them to update their payment method if possible.'
        );
        return;
      } else {
        logEvent(
          'created_offer',
          {
            items: [getItem(currProduct)],
            offer_id: offer.id,
          },
          userDoc.uid
        );

        const messageText = getOfferMessage(
          currProduct.title,
          offer.price,
          offer.shipping_cost,
          offer.tax,
          offer.message,
          [],
          currProduct.size,
          offer.address
        );

        if (offer.chat_id) {
          const newMessage: ChatMessageDocument = {
            uid: userDoc?.uid || '',
            content: messageText,
            created_at: Date.now(),
            is_auto: false,
            is_img: false,
            offer_amount: offer.price,
          };
          const chat = await getChatDocumentById(offer.chat_id);
          if (chat) {
            await sendMessageToChat(newMessage, chat);
            await updateOffer(offer.id, newOffer);
          } else {
            logError('No chat found for offer');
          }
        }
        queryClient.invalidateQueries({
          queryKey: ['offer', offer_id],
        });
        queryClient.invalidateQueries({
          queryKey: ['orders', userDoc?.uid],
        });
      }
    } catch (error) {
      logError(error);
      showErrorToast('Error countering offer: ' + (error as Error).message);
    }
  };

  const [selectedPercentage, setSelectedPercentage] = useState<number | null>(
    15
  );

  const getNewPrice = (price: number, percentage: number) => {
    return (
      Number(price ?? 0) -
      (Number(price ?? 0) * percentage) / 100
    ).toFixed(2);
  };

  const getNetEarnings = (
    offer: Pick<
      OfferDocument,
      'total' | 'shipping_cost' | 'rate_id' | 'price' | 'address' | 'tax'
    >
  ) => {
    if (!product) return 0;
    return calculateEarningsFromOffer(offer, seller?.tax_states ?? [], product);
  };

  return {
    offer: realtimeOffer,
    product,
    isOpen,
    isAcceptOpen,
    isCounterOpen,
    setIsOpen,
    setAcceptIsOpen,
    setCounterIsOpen,
    declineThatOffer,
    acceptThatOffer,
    counterOffer,
    selectedPercentage,
    setSelectedPercentage,
    getNewPrice,
    getNetEarnings,
  };
};
