'use client';

import React, { useState } from 'react';
import FormCurrencyInputFancy from '@c/forms/controls/FormCurrencyInputFancy';
import FormLabel from '@c/forms/controls/FormLabel';
import BaseModal from '@c/modals/BaseModal';
import { zodResolver } from '@hookform/resolvers/zod';
import Button from '@ui/Button';
import { ProductDocument, productSchema } from 'models/product';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { formatCurrency } from '@util/index';
import PriceDropIcon from './assets/price-drop.svg';
import Image from 'next/image';
import { LightBulbIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { useQueryClient } from '@tanstack/react-query';
import { updateProductByKeys } from '@util/firestore/products';
import { deleteField } from 'firebase/firestore';
import { useToastContext } from 'context/ToastContext';
import { logError } from '@util/logError';

export default function PriceDropModal({
  open,
  setOpen,
  product,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  product: ProductDocument;
}) {
  const getNewPrice = (percentage: number) => {
    return (
      Number(product.price) -
      (Number(product.price) * percentage) / 100
    ).toFixed(2);
  };

  const priceDropSchema = z.object({
    price: z
      .number({
        required_error: 'Price is required',
        invalid_type_error: 'Please enter a valid price',
      })
      .gte(1, 'The new price cannot be lower than $1')
      .refine(
        (value) => value < product.price * 0.95,
        `The new price must be lower than ${formatCurrency(
          product.price * 0.95
        )}`
      ),
  });

  type PriceDropModalFormData = z.infer<typeof priceDropSchema>;

  const [selectedPercentage, setSelectedPercentage] = useState<number | null>(
    15
  );

  const [loading, setLoading] = useState(false);

  const { showSuccessToast, showErrorToast } = useToastContext();

  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm<PriceDropModalFormData>({
    resolver: zodResolver(priceDropSchema),
    defaultValues: {
      price: product ? Number(getNewPrice(15)) : 0,
    },
  });

  const queryClient = useQueryClient();

  const onConfirmPriceDrop = async (data: PriceDropModalFormData) => {
    try {
      setLoading(true);

      if (product.shipment?.package?.insured_value?.amount)
        product.shipment.package.insured_value.amount = data.price;

      const keys = productSchema.pick({
        price: true,
        on_sale: true,
        shipment: true,
        date_updated: true,
      });
      const updates = {
        price: data.price,
        on_sale: true,
        floor_price: deleteField(),
        ...(product.shipment ? { shipment: product.shipment } : {}),
        date_updated: Date.now(),
      };
      const res = keys.safeParse(updates);
      if (res.success) {
        await updateProductByKeys(product.id, {
          ...updates,
        });
        queryClient.invalidateQueries({
          queryKey: ['listings'],
        });
      } else {
        logError(res.error);
        showErrorToast('Error updating product. Please contact support.');
      }
    } catch (e) {
      showErrorToast((e as Error).message);
    } finally {
      showSuccessToast('Price updated');
      setOpen(false);
      setLoading(false);
    }
  };

  if (!product) {
    return null; // Render nothing if product is not available yet
  }

  return (
    <BaseModal isOpen={open} dismiss={() => setOpen(false)} hideCloseIcon>
      <div className="flex flex-col gap-y-8 p-8">
        <div className="-mt-8 flex w-full items-center justify-between">
          <Image src={PriceDropIcon} alt="Price Drop" width={70} height={70} />

          <XMarkIcon
            className="h-12 w-12 cursor-pointer"
            onClick={() => setOpen(false)}
          />
        </div>

        <div className="-mt-8 flex flex-col gap-y-2">
          <h1 className="flex items-center gap-[1.6rem] text-[2.5rem] font-semibold">
            Price Drop
          </h1>
          <span className="max-w-[45rem] pt-2 text-[1.8rem] text-brand-dark-gray">
            Decrease the price of your product and automatically put it on sale.
          </span>
        </div>

        <div className="grid w-full grid-cols-3 gap-x-4">
          {[10, 15, 20].map((percentage) => (
            <div
              className={`rounded-3xl border p-6 ${
                selectedPercentage === percentage
                  ? 'bg-black text-white'
                  : 'border-brand-primary text-black hover:border-brand-secondary hover:text-brand-secondary'
              } flex cursor-pointer items-center justify-center gap-x-2 text-[1.8rem] `}
              onClick={() => {
                setValue('price', Number(getNewPrice(percentage)));
                setSelectedPercentage(percentage);
              }}
              key={percentage}
            >
              <div className="flex flex-col items-center">
                <h1 className={'text-[2rem] font-semibold'}>
                  {formatCurrency(Number(getNewPrice(percentage)))}
                </h1>

                <span className="text-[1.5rem]">{percentage}% OFF</span>
              </div>
            </div>
          ))}
        </div>

        <div className={'flex items-center justify-center gap-x-4 pt-6'}>
          <div className="h-px w-72 bg-brand-lighter-gray" />

          <span className="text-[1.6rem] text-zinc-600">or</span>

          <div className="h-px w-72 bg-brand-lighter-gray" />
        </div>

        <form onSubmit={handleSubmit(onConfirmPriceDrop)}>
          <div className="flex w-full justify-center gap-[1.6rem] p-[1.6rem] text-center">
            <FormLabel
              value="New Price"
              required
              errorMessage={errors.price?.message}
            >
              <Controller
                control={control}
                name={'price'}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div className="flex w-full justify-center">
                    <div className="my-4 max-w-[20rem]">
                      <FormCurrencyInputFancy
                        placeholder={'0.00'}
                        error={!!errors?.price}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value?.toString() ?? ''}
                        allowEmpty
                      />
                    </div>
                  </div>
                )}
              />
            </FormLabel>
          </div>

          <div className="flex w-full items-start gap-x-4 rounded-lg bg-red-100 px-12 py-8 text-black">
            <LightBulbIcon className="h-14 w-14 text-red-500" />

            <h3 className="max-w-[45rem] text-2xl">
              You currently have Smart Pricing enabled. Adjusting the price will
              disable Smart Pricing.
            </h3>
          </div>

          <div className="flex w-full justify-end gap-x-2 pt-8">
            <Button
              text="Cancel"
              onClick={() => setOpen(false)}
              type="tertiary"
            />

            <Button
              text={loading ? 'Loading...' : 'Confirm'}
              type="secondary"
              buttonType="submit"
            />
          </div>
        </form>
      </div>
    </BaseModal>
  );
}
