import { z } from 'zod';
import { dateTimeSchema, uidSchema } from './shared';

export const chatMessagesSchema = z.object({
  content: z.string().min(1).max(20_000),
  content_html: z.string().optional(),
  auto_recipient: z.string().optional(),
  created_at: dateTimeSchema.default(Date.now()),
  is_img: z.boolean().default(false),
  is_auto: z.boolean().default(false),
  mime_type: z.string().optional(),
  offer_amount: z.number().optional(), // same as offer price or counter offer amount
  skip_notification: z.boolean().optional(),
  thumbnail: z.string().optional(),
  download_url: z.string().optional(),
  uid: uidSchema,
});

export const chatSchema = z.object({
  buyer_id: uidSchema,
  case_num: z.number().gt(1).optional(),
  created_at: dateTimeSchema.default(Date.now()),
  flagged: z.boolean().default(false),
  from_web: z.boolean().default(true),
  id: z.string().min(10),
  is_offer: z.boolean().default(false),
  last_time: z.number().optional().default(0),
  messages: z.array(chatMessagesSchema).default([]),
  offer_id: z.string().optional(),
  order_id: z.string().optional(),
  order_num: z.number().optional(),
  product_id: z.string().optional(),
  seller_id: uidSchema,
  uids: z.array(uidSchema).max(3).default([]), // [buyer_id, seller_id]
  unread: z.record(z.boolean()).default({}),
  is_expert: z.boolean().default(false),
  expert_state: z.number().int().gte(0).lte(4).optional(),
  is_auto: z.boolean().default(false).optional(),
  support_state: z.number().int().optional(), // 0: New Ticket, 1: Awaiting Customer Reply, 2: Awaiting Support Reply, 3: Solved, 4: Cancelled
  support_assigned: uidSchema.optional(),
});

export enum SupportState {
  NewTicket = 0,
  AwaitingReply = 1,
  ReviewLater = 2,
  Resolved = 3,
  Cancelled = 4,
}

export const SupportStateToLabel = {
  [SupportState.NewTicket]: 'New Ticket',
  [SupportState.AwaitingReply]: 'Awaiting Reply',
  [SupportState.ReviewLater]: 'Review Later',
  [SupportState.Resolved]: 'Resolved',
  [SupportState.Cancelled]: 'Cancelled',
};

export const SupportStateList = [
  SupportStateToLabel[SupportState.NewTicket],
  SupportStateToLabel[SupportState.AwaitingReply],
  SupportStateToLabel[SupportState.ReviewLater],
  SupportStateToLabel[SupportState.Resolved],
  SupportStateToLabel[SupportState.Cancelled],
];

export interface ChatMessageInput extends z.input<typeof chatMessagesSchema> {
  created_at: number;
}
export interface ChatMessageDocument
  extends z.output<typeof chatMessagesSchema> {}
export interface ChatInput extends z.input<typeof chatSchema> {
  created_at: number;
}
export interface ChatDocument extends z.output<typeof chatSchema> {
  // for caching
  has_unread?: boolean;
  last_msg?: string;
}
